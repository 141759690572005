export const STORAGEKEY = {
  token: "token",
  authData: "authData",
  userData: "logindata",
  layoutData: "layoutData",
  patientName: "patientName",
  chatMessage: "chatMessage",
};

export const AppSettings = {
  defaultNavigation: "/dashboard",
};

export const AppUser = {
  entertainer: "entertainer",
  person: "person",
  admin: "admin",
};
