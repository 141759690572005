import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loginimg from "../Loginimg/Loginimg.js";
import { Row, Col } from "react-bootstrap";
import * as EmailValidator from "email-validator";
import { ErrorToast } from "../../Toaster/Toaster";
import {  ApiPostAuth } from "../../../helpers/API/API_data";
import { encryptdata } from "../../../helpers/Encrypt.js";
import Auth from "../../../helpers/Auth.js";
import { appContext } from "../../../helpers/AppContext.js";

const Login = () => {
  const [user, setUser] = useState("");
  const [loader, setloader] = useState(false);
  const { connectSocket } = useContext(appContext);
  const isAuth = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // submit email and passowd to login system
  const submit = async (e) => {
    
    setloader(true);
    e.preventDefault();
    if (!user?.email && !user?.password) {
      ErrorToast("Email and Password are Requried!");
    } else if (!user?.email) {
      ErrorToast("Email is Requried!");
    } else if (!EmailValidator.validate(user?.email)) {
      ErrorToast("Invalid Email!");
    } else if (!user?.password) {
      ErrorToast("Password is Requried!");
    } else {
      localStorage.clear();
      const b = {
        email: user.email,
        password: user.password,
      };
      const encryptedLoginData = encryptdata(b);
      ApiPostAuth("patient/login", { encryptedLoginData })
      .then(async(res)=>{
        Auth.setAuthToken(res?.data?.data?.token);
        localStorage.setItem("logindata",JSON.stringify(res?.data?.data));
        if(res.status === 200 ) {
          navigate("/dashboard")
          connectSocket()
        }
        return res;
      })
      .catch((e) => {
        console.log("eeee", e);
        ErrorToast(e?.message);
      });
      setloader(false);
    }
  };
  useEffect(() => {
    if (isAuth) {
      // new treatment
      if (JSON.parse(localStorage.getItem("logindata"))?.isNewTreatment) {
        navigate("/home");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuth]);

  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <>
      <section className="login">
        <Row>
          <Col lg={6}>
            <Loginimg />
            <p className="welcometext">
              Welcome Back! Enter your email and password below to sign in.
            </p>
          </Col>
          <Col lg={6}>
            <div className="loginuserdetail">
              <h3>Welcome Back! </h3>
              <form className="inputdetail" onSubmit={submit}>
                <div className="userinput">
                  {" "}
                  <span>Email address</span>
                  <input
                    autocomplete="off"
                    autoCorrect="false"
                    autoFocus="false"
                    type="text"
                    name="email"
                    value={user.name}
                    className="textWhite"
                    // placeholder="Email address"
                    onChange={handleChange}
                  />
                </div>
                <div className="userinput">
                  {" "}
                  <span>Password</span>
                  <input
                    autocomplete="off"
                    autoCorrect="false"
                    autoFocus="false"
                    type="password"
                    name="password"
                    className="textWhite"
                    value={user.password}
                    // placeholder="Password"
                    onChange={handleChange}
                  />
                </div>
                <div className="forgott">
                  <h6
                    className="text-white cursor-pointer text-end"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </h6>
                </div>
                <button type="submit" className="btnred">
                  {" "}
                  {loader ? "loading..." : "Login"}
                </button>
              </form>
              <div className="signuplink">
                <p>
                  Don’t have an account?
                  <Link to="/home">Sign Up</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Login;
